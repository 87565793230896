<template>
  <div>
    <section class="white">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <h2
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
              class="mb-4"
            >
              ΕΠΑΝΑΧΟΡΗΓΗΣΗ ΑΔΕΙΑΣ ΟΔΗΓΗΣΗΣ ΠΟΥ ΑΦΑΙΡΕΘΗΚΕ ΛΟΓΩ Σ.Ε.Σ.Ο.
            </h2>

            <br>
            <p class="display-1">
              ΑΠΑΙΤΟΥΜΕΝΑ ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ
            </p><p class="subtitle-1">
              <ul>
                <li>Αίτηση – Υπεύθυνη Δήλωση του άρθρου 8 του Ν. 1599/86</li>
                <li>Φωτοαντίγραφο αστυνοµικής ταυτότητας ή ισχύοντος ελληνικού διαβατηρίου ή ατοµικού βιβλιαρίου υγείας</li>
                <li>Φωτοαντίγραφο οποιουδήποτε ισχύοντος δημόσιου εγγράφου, εφόσον ο ενδιαφερόμενος δεν είναι Έλληνας υπήκοος</li>
                <li>Μία (1) πρόσφατη φωτογραφία τύπου διαβατηρίου</li>
                <li>Αποδεικτικό πληρωµής 15,00€ για κάθε προγραµµατισµό θεωρητικής εξέτασης (κωδικός e-παραβόλου «2037») και δοκιµασίας προσόντων και συµπεριφοράς του υποψηφίου (κωδικός e-παραβόλου «2038»)</li>
                <li>
                  Σε περίπτωση χορήγησης νέου εντύπου άδειας οδήγησης λόγω αντικατάστασης απαιτούνται επιπλέον:
                  <ul>
                    <li>Αποδεικτικό πληρωµής 30,00€ για την εκτύπωση της άδειας οδήγησης (κωδικός e-παραβόλου «28»)</li>
                    <li>Αίτηση εκτύπωσης της άδειας οδήγησης µε µία (1) πρόσφατη φωτογραφία, τύπου διαβατηρίου</li>
                    <li>Aποδεικτικό πάγιου τέλους χαρτοσήµου και εισφοράς υπέρ τρίτων 9,02€ (κωδικός e-παραβόλου «66»), εάν η άδεια οδήγησης είναι φθαρµένη</li>
                  </ul>
                </li>
                <li>
                  Σε περίπτωση έκδοσης αντιγράφου άδειας οδήγησης λόγω απώλειας ή κλοπής η οποία αποδεικνύεται µε σχετική Υπεύθυνη Δήλωση του άρθρου 8 του Ν. 1599/86 που έχει αποσταλεί από το οικείο Αστυνοµικό Τµήµα αντί της άδειας απαιτούνται επιπλέον:
                  <ul>
                    <li>Αποδεικτικό πληρωµής 30,00€ για την εκτύπωση της άδειας οδήγησης (κωδικός e-παραβόλου «28»)</li>
                    <li>Αποδεικτικό πάγιου τέλους χαρτοσήµου και εισφοράς υπέρ τρίτων 9,02€ (κωδικός e-παραβόλου «66»)</li>
                    <li>Αποδεικτικό πληρωµής 30,00€ (κωδικός e-παραβόλου «29»)</li>
                  </ul>
                </li>
                <li>Σε περίπτωση διόρθωσης της ηµεροµηνίας γέννησης υποβάλλεται πρόσφατο πρωτότυπο πιστοποιητικό γέννησης, από το οποίο προκύπτει η ορθή ηµεροµηνία γέννησης, ενώ σε περίπτωση διαφοροποίησης ονόµατος ή επωνύµου υποβάλλεται αµετάκλητη δικαστική απόφαση, διαζευκτήριο ή βεβαίωση οικείου Δήµου, από τα οποία να προκύπτει η ταυτοπροσωπία του κατόχου της άδειας οδήγησης µε τον/την αναφερόµενο/η στα έγγραφα αυτά</li>
                <br>
              </ul>
            </p>
            <v-btn
              color="success"
              class="px-5"
              large
              :to="`/contact-me`"
            >
              Επικοινωνηστε τωρα
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="5"
            offset-md="1"
          >
            <v-card>
              <v-img src="/static/sideServices/Επαναχορήγηση Άδειας Που Αφαιρέθηκε (ΣΕΣΟ).jpg" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="white pt-0">
      <v-container>
        <div class="text-center font-weight-bold">
          <v-text>
            Τα παράβολα εκδίδονται απο την εφορία ή ηλεκτρονικά απο το <a href="https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm?fbclid=IwAR3fE4tXJ-I1NlFsMfud2NLlWjn13VrFOmBm7AICQlCda0kdDPQt2XN73ww">e-paravolo</a> και τα αναλαμβάνει και η σχολή να τα εκδώσει χωρίς επιβάρυνση!
          </v-text>
        </div>
      </v-container>
    </section>

    <section class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col
            v-for="([name, path], i) in pdfs"
            :key="i"
            cols="12"
            md="12"
          >
            <div class="text-center">
              <div
                class="headline font-weight-bold mb-3"
                v-text="name"
              />
              <v-btn
                color="success"
                class="px-5"
                large
                @click="readFile(path)"
              >
                ΛΗΨΗ
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      pdfs: [
        ['Εκτύπωση Αίτησης', '../static/Επαναχορηγηση-αδειας-που-αφαιρεθηκε-λόγω-ΣΕΣΟ-ΑΙΤ.pdf']
      ]
    }),

    methods: {
      readFile (path) {
        window.open(path, '_blank')
      }
    }
  }
</script>
